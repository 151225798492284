<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">电费项目列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editElectric">添加电费项目</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-select-remote-search label="电费项目" v-model="pageParam.params.id" :options="optionsElectric" placeholder="请选择电费项目(可搜索)" />
                <!-- <le-input label="电表号" v-model="pageParam.params.ammeterNumber" /> -->
                <le-select-remote-search label="电表号" v-model="pageParam.params.ammeterId" :options="optionsAmmeter" placeholder="请选择电表(可搜索)" />
                <le-select-remote-search label="物业" v-model="pageParam.params.propertyId" :options="optionsProperty" placeholder="请选择物业(可搜索)" />
            </le-search-form>
            <le-pagview ref="electricListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.electricList" :pageSizeDefault='10'>
                <el-table 
                    ref="electricList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    :span-method='mergeCols'
                    :border='true'
                    style="width: 100%">
                    <el-table-column prop="nameId" label="电费项目名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="chargeFeeTypeId" label="电费收取方式" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.chargeFeeType | initDic(chargeFeeTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="chargePayeeTypeId" label="电费收款方" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.chargePayeeType | initDic(chargePayeeTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="propertyListId" label="收款物业方" min-width="120">
                        <template slot-scope="{ row }">
                            <span v-if="row.propertyList.length == 1 && !row.propertyList[0]">-</span>
                            <div class="a-flex-rfsc a-flex-wrap" v-else>
                                <span class="stationTag" v-for="(item,index) in row.propertyList" :key="index">
                                    {{ item.propertyName?item.propertyName:'-' }}，占比{{ item.rate/100 }}%
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unitPriceId" label="电费（/元/kwh）" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.unitPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationId" label="站点" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="电表号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.meterNumber || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toElectricInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="editElectric(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="删除" placement="top">
                                <img src="../../assets/icon/option-refuse.png" class="a-wh-16" @click="delElectricItem(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: null,
                optionsElectric: {
                    url: this.$Config.apiUrl.electricFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                optionsProperty: {
                    url: this.$Config.apiUrl.propertyCompanyfilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                optionsAmmeter: {
                    url: this.$Config.apiUrl.ammeterFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                chargeFeeTypeDic: [],
                chargePayeeTypeDic: [],
            }
        },
        computed: {
            // 获取所有单元格合并数据
            spanArr() {
                if(!this.tableData.length) return []
                const mergeCols = ['nameId','chargeFeeTypeId','chargePayeeTypeId','propertyListId','unitPriceId','stationId','ammeterId','pid'] // 需要合并的列（字段）
                return util.getMergeCells(this.tableData, mergeCols, mergeCols)
            }
        },
        created () {
            this.$getDic('chargeFeeType','select').then(res=>{ this.chargeFeeTypeDic = res; })
            this.$getDic('chargePayeeType','select').then(res=>{ this.chargePayeeTypeDic = res; })
            this.pageParam = {
                url: this.$Config.apiUrl.electricPage,
                method: "post",
                params: {
                    ammeterId: '',
                    propertyId: '',
                    id: ''
                },
                freshCtrl: 1,
            }
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取列表
            setTableData(data) {
                let arr = []
                // 遍历处理后台返回数据
                data.map((item,index)=>{
                    item.stationAmmeterList.map((ite,idx)=>{
                        let obj = {
                            ...ite,
                            chargeFeeType: item.chargeFeeType,
                            chargePayeeType: item.chargePayeeType,
                            pid: item.id,
                            name: item.name,
                            propertyList: item.propertyList,
                            unitPrice: item.unitPrice || 0,
                            updateTime: item.updateTime,
                            nameId: item.id,
                            propertyListId: item.id,
                            unitPriceId: item.id,
                            chargeFeeTypeId: item.id,
                            chargePayeeTypeId: item.id,
                        }
                        arr.push(obj)
                    })
                })
                this.tableData = arr
            },
            handlerRest() {
                this.pageParam.params = {
                    ammeterId: '',
                    propertyId: '',
                    id: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['electricListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            editElectric (datas) {
                this.$router.push({
                    path: '/electric/electric-edit',
                    query:{
                        id: datas?datas.pid:'',
                    }
                })
            },
            // 查看电费项目详情
            toElectricInfo (datas) {
                this.$router.push({
                    path: '/electric/electric-info',
                    query:{
                        id: datas?datas.pid:'',
                    }
                })
            },
            // 删除电费项目
            delElectricItem (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.electricDel,
                        method: "post",
                        params: {
                            id: datas.pid
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                })
                .catch(_=>{})
            },
            // 合并行
            mergeCols ({ row, column, rowIndex, columnIndex }) {
                return this.spanArr[rowIndex][columnIndex]
            },
            exportfile () {
                // this.$exfile({
                //     code: 15,
                //     fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                //     startTime: this.pageParam.params.searchStartTime,
                //     endTime: this.pageParam.params.searchEndTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.s-btn-add{
    width: 135px;
}
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>